

.arrow-button{


	height:20px;
	width:40px;

	font-size:1.2rem;
	display:flex;
	justify-content:center;
	align-items:center;
	border-radius:50px;

	background-color:var(--navbar);
	color:var(--ivory);
}
