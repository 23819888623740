
.navbar{
        height:56px;
        width:98vw;
        padding:8px;
        border-radius:3px;
        display:flex;
        justify-content:space-between;
        align-items:center;
	background:var(--navbar);
	position:fixed;
	z-index:999;
	color:var(--ivory);
	box-shadow: 2px 2px 10px black; 	

}


.navbar * {
      	user-select:none; 
}


.navbar-logo{
	color:var(--ivory);
        margin-right:auto;
        margin-left:4%;
        position:relative;
        z-index:10;
        text-decoration:none;
}

.navbar-icon{
        margin-left:auto;
        margin-right:5%;
}


