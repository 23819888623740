

.menu-item{
        text-decoration:none;
	user-select:none;
	cursor:pointer;
	width:100%;
	min-height:85px;
	color:var(--ivory);
	box-shadow:-2px 1px 20px -10px black inset;
	display:inline-block;
	position:relative;
	z-index:80;
}


.menu-item:active{
	box-shadow:-1px 0px 46px -12px black inset;
	color:#00b9f2;
}


.menu-text{
	padding-left:58px;
	display:flex;
	flex-direction:row;
	gap:42px;
	align-items:flex-start;
	margin-top:32px;
	
}


.menu-text p{
	width:89px;
	text-align:center;
}



.dropdown-menu {
	position:relative;
	list-style:none;
	background:var(--light-grey);
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	transition: height .4s ease, opacity .3s ease ;
	user-select:none;
}

.dropdown-menu  * {

	user-select:none;
}



.dropdown-active{
	opacity:10;
}
.dropdown-active li {
	height:65px;
	opacity:1;
}

.dropdown-deactive{
	height:0;
	opacity:0;
	pointer-events:none;
}

.dropdown-deactive li{
	height:0;
	opacity:0;
}
.dropdown-menu li  {
	width:100%;
	border-bottom:1px solid black;
	display:flex;
	align-items:center;
	justify-content:center;
	transition:height .5s ease, opacity .5s ease;
	z-index:80;

}




.dropdown-menu li a {
	color:var(--ivory);
	text-decoration:none;
	width:100%;
	text-align:center;
	line-height:65px;

}


.dropdown-menu li a:target {
	background:transparent;
}

.dropdown-menu li a:active{
	color:#00b9f2;
}

