






.confirm-modal{



	min-width:150px;
	max-width:400px;

	padding:23px;
	background: rgba(12,23,19,.99);
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
}



.confirm-modal > p{

	padding:15px
}



.modal-btns{
	width:100%;
	margin-top:23px;
	display:flex;
	justify-content: space-around;
}




@media only screen and ( max-width:430px ){


.modal-btns{
	flex-direction:column;
	gap:23px;
}


}
