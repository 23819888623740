




.error-message{
	color:red;
	font-size:.7rem;
}



.login-container{


	height:100%;

	width:100%;
	display:flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:center;
}

.login-container .text-input-container:nth-child(3) {

	margin-top:20px;

}
