



.email-modal{

	min-height:20vh;
	min-width:345px;
	padding: 12px 22px;
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
	overflow-Y:auto;
	display:flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:center;
	background:var(--modal-card-background);
}


.email-btn-wrap{
	margin:13px 0 0 0;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:space-around;
}
