@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
:root {
  --background: #f7f7f7;
  --black: #0c1713;
  --orange: #ff9b42;
  --salmon: #f7a072;
  --light-blue: #0fa3b1;
  --blue: #3d82c2;
  --champigne: #eddea4;
  --white: #fcfcff;
  --border: #d97720;
  --yellow: #f9f871;
  --dark-blue: #005361;
  --red: #e1402b;
  --grey: #9d9995;
  --menu-grey: #4f4f4f;
  --light-grey: #737375;
  --dark: #242424;
  --navbar: #393e41;
  --ivory: #f6f7eb;
  --text: #402e32;
  --orange-contr: #12078a;
  --add-icon: #008a64;
  --modal-card-background: rgba(12, 23, 19, 0.9);
  --btn-green: #05711b;
  --btn-yellow: #b76a06;
  --purple: #d63fdb;
  --pranzo: #ffd700;
  --happy-pn: #fb7eff;
  --vr: #03c460;
  --laser: #03c460;
}

/*
 *
	--navbar:#FF9B42;
 *
 * */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

html {
  height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  font-size: 16px;
  color: #0c1713;
  color: var(--black);
}

body {
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  background-color: #f7f7f7;
  background-color: var(--background);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media only screen and (max-width: 760px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: 330px) {
  html {
    font-size: 13px;
  }
}


.card-page{

	text-align:center;
	width:100vw;
	padding-bottom:16vh;
	height:80vh;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-content:center;
	align-items:center;
}

.card-page h1, h2, h3{
	color: var(--ivory);
}


.prenotazioni-page{
	display:flex;
	flex-direction:column;
	align-items:center;
}


.page{
	padding-top:89px;
}


.calendar-page{
	padding:58px;
}

.modal-container{
	position:fixed;
	width:100vw;
	top:0;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	background: rgba(12,23,19,.5);
	z-index:999;
}


@media only screen and (max-width:840px){


}

@media only screen and (max-width:1500px){
	.calendar-page{
		padding:12px;
	}
}

@media only screen and (max-width:330px){
	.calendar-page{
		padding:0;
	}
}






.error-message{
	color:red;
	font-size:.7rem;
}



.login-container{


	height:100%;

	width:100%;
	display:flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:center;
}

.login-container .text-input-container:nth-child(3) {

	margin-top:20px;

}





.text-input-container{

	max-width:20vw;
	min-width:200px;
        padding:0;
        position:relative;
}



.text-input{

	width:100%;
	background:var(--white);
	border:none;
	border:2px ridge var(--light-blue);
	border-radius:4px;
	padding:.3rem;
	-webkit-user-select:auto;
	    -ms-user-select:auto;
	        user-select:auto;

}


.text-input:focus{

	border:2px ridge var(--orange);

}



.input-label{
	font-size:.9rem;
	left:10px;
	transition: 	.1s font-size ease-in,
       			.1s top ease-in,
		       	.1s left ease-in,
			.1s color ease-in;

	position:absolute;
	-webkit-user-select:none;
	    -ms-user-select:none;
	        user-select:none;
	opacity:.7;
}

.error-label{
	color:red;
	font-size:.7rem;
	line-height:15px;
	position:absolute;
}







.btn{
	min-height:30px;
	min-width:150px;
	width: auto;
	max-width: 250px;
	position:relative;
	overflow:hidden;
	background:var(--light-blue);
	border-radius:4px;
	border:none;
	color:var(--white);
	padding:.3rem;
	box-shadow:1px 1px 5px black;
	letter-spacing:.05rem;
	font-size:.8rem;
}



.btn:active{
	box-shadow:1px 1px 1px black;
}


.primary{
        background-color:var(--light-blue);
        border:1px solid var(--light-blue);
        box-shadow:.4px .4px 1px rgba(0, 126, 167, .5);
}



.secondary{
        background-color:var(--red);
        border:1px solid var(--red);
        box-shadow:.4px .4px 1px rgba(225, 64, 43, .5);
}


.green{
        background-color:var(--btn-green);
        border:1px solid var(--btn-green);
        box-shadow:.4px .4px 1px rgba(5, 85, 2, 0.5);
}


.yellow{

        background-color:var(--btn-yellow);
        border:1px solid var(--btn-yellow);
        box-shadow:.4px .4px 1px rgba(5, 85, 2, 0.5);

}


.ripple {
        z-index:100;
        position: absolute;
	display:inline-block;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
	background:rgb(240,240,240);
        pointer-events: none;
        border-radius: 50%;
        -webkit-animation: animate .5s linear forwards;
                animation: animate .5s linear forwards;
}

	
	
@-webkit-keyframes animate {
        0% {
                width: 0px;
                height: 0px;
                opacity: 0.5;
        }
        100% {
                width: 500px;
                height: 500px;
                opacity: .1;
        }
}

	
	
@keyframes animate {
        0% {
                width: 0px;
                height: 0px;
                opacity: 0.5;
        }
        100% {
                width: 500px;
                height: 500px;
                opacity: .1;
        }
}



@media only screen and ( max-width:330px ){


	.btn{
		min-height:unset;
	}

}






.card{

	width:30vw;
	min-width:255px;
	max-width:350px;
	min-height:246px;
	padding: .5rem; 
	padding-top:2rem;
	border-radius:4px;

	background:var(--navbar);

	display:flex;
	flex-direction:column;
	justify-content:space-around;

	box-shadow:3px 3px 20px black;
	
}








.loader-wrap{

	display:flex;
	grid-gap:3rem;
	gap:3rem;
	align-items:center;
}





.loader-wrap .loader-circle:nth-child(1){
	-webkit-animation: ball1 ease-out 1.3s infinite;
	        animation: ball1 ease-out 1.3s infinite; 
}

.loader-wrap .loader-circle:nth-child(2){
	-webkit-animation: ball1 ease-out 1.3s infinite .25s;
	        animation: ball1 ease-out 1.3s infinite .25s; 
}


.loader-wrap .loader-circle:nth-child(3){
	-webkit-animation: ball1 ease-out 1.3s infinite .5s;
	        animation: ball1 ease-out 1.3s infinite .5s; 
}

.loader-wrap .loader-circle:nth-child(4){
	-webkit-animation: ball1 ease-out 1.3s infinite .75s;
	        animation: ball1 ease-out 1.3s infinite .75s; 
}



.loader-circle{

	width:5px;
	height:5px;
	border-radius:4rem;
	background:var(--light-blue);


}






@-webkit-keyframes ball1 {


	0%{
		opacity:100%;
		-webkit-transform:scale(100%);
		        transform:scale(100%);
	}
	100%{
		opacity:0%;
		-webkit-transform:scale(750%);
		        transform:scale(750%);
	}
}






@keyframes ball1 {


	0%{
		opacity:100%;
		-webkit-transform:scale(100%);
		        transform:scale(100%);
	}
	100%{
		opacity:0%;
		-webkit-transform:scale(750%);
		        transform:scale(750%);
	}
}










.prenotazioni-btn-wrap{
	margin-top:30px;
	margin-bottom:30px;
	min-width:300px;
	display:flex;
	justify-content: center;
	grid-gap: 86px;
	gap: 86px;
}

@media only screen and ( max-width:330px ){
	.prenotazioni-btn-wrap{
		grid-gap: 40px;
		gap: 40px;
		flex-direction:column;
		width:80vw;
		min-width:unset;
	}
}





.text-list-container{
	display:flex;
	flex-direction:column;
	grid-gap:24px;
	gap:24px;
}


.text-list-container .text-input{
	height:25px;
}






.textarea-container{
	display:flex;
	flex-direction:column;
	align-items:flex-start;
}

.textarea{
	resize:none;
	background:var(--white);
	border:none;
	border:2px ridge var(--light-blue);
	border-radius:4px;
	padding:.3rem;

}


.textarea:focus{

	border:2px ridge var(--orange);

}




.divider{
	display:flex;
	margin:0 auto;
	align-items:center;
	width:90vw;

}


.title{

	color:var(--dark-blue);
	white-space: nowrap;
	margin-right:16px;
}

.line{
	height:1px;
	border-bottom:1px solid var(--border);
	width:100%;
}


.dropdown{


	height:28px;
	background:white;
	padding:0 20px;
	border-radius:5%;
}



.dropdown-container{

	position:relative;
}





.form{

	height:100%;
	width:95%;
	margin:12px auto;
	padding:44px;
	display:flex;
	grid-gap:60px 40px;
	gap:60px 40px;
	align-items:flex-end;
	justify-content:flex-start;
	flex-wrap:wrap;
	top:0;
	background:var(--navbar);
	border-radius:10px;
	transition: height .2s ease-in, opacity .2s ease-in  ;
	pointer-events: all;
}


.form div input,textarea{
	-webkit-user-select:auto;
	    -ms-user-select:auto;
	        user-select:auto;
}


.label-fix{
	color:var(--light-blue);
	font-weight:bolder;
	font-size:.7rem;
	margin-left:2px;
	position:absolute;
	-webkit-transform:translateY(-25px);
	        transform:translateY(-25px);
	white-space: nowrap;
}


@media only screen and ( max-width:330px ){
	.form{
		padding-left:13px;
		padding-right:13px;
	}
}



.modal-close-icon{
	width:100%;
	margin-bottom:23px;
	font-size:1.6rem;
	color:white;
	display:flex;
	justify-content:flex-end;
}


.event-detail{
	width:480px;
	max-width:100vw;
	min-height:150px;
	max-height:80vh;
	padding: 12px 34px;
	background: var(--modal-card-background);
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
	overflow-Y:auto;
	display:flex;
	flex-direction:column;
}


.event-detail  > button{
	margin:0 auto 0 auto;
	margin-bottom:13px;
}


.detail-modal-list{



	max-height:60vh;
	list-style:none;
	margin-bottom:23px;
	overflow-Y:auto;
}


.detail-modal-list label{

	font-weight:bold;
	color:#e39c13;
}



.detail-modal-list span{
	
	width:100%;
	overflow-wrap:anywhere;
	color:white;
	-webkit-user-select:text;
	    -ms-user-select:text;
	        user-select:text;
}





@media only screen and (max-width:430px ){

	.event-detail{
		max-height:97vh;
	}
}



.modal-btn-action-wrap{

	width:100%;
	display:flex;
	flex-direction:column;
	grid-gap:18px;
	gap:18px;
	justify-content: center;
	align-items: center;
}


@media only screen and (max-width:430px ){

	.modal-btn-action-wrap{
		grid-gap:13px;
		gap:13px;
	}
}









.confirm-modal{



	min-width:150px;
	max-width:400px;

	padding:23px;
	background: rgba(12,23,19,.99);
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
}



.confirm-modal > p{

	padding:15px
}



.modal-btns{
	width:100%;
	margin-top:23px;
	display:flex;
	justify-content: space-around;
}




@media only screen and ( max-width:430px ){


.modal-btns{
	flex-direction:column;
	grid-gap:23px;
	gap:23px;
}


}






.table-container{

	overflow:auto;
	width:80vw;
	margin:5vh auto;
	max-height:90vh;
	border-radius:3px;
	border:1px solid var(--grey);
	z-index: 20;
	position: relative;
	display:block;
}




.table-print{
	margin:0;
	height:-webkit-max-content;
	height:max-content;
	width:-webkit-max-content;
	width:max-content;
	visibility:visible;
	overflow:visible;
}

.table-print .table-row {
	height:-webkit-max-content;
	height:max-content;
	border:none;
	margin:5px 0;
}




.table-print .table-row .cella{
	background:white;
	border:none;
	padding:5px 2px;
	margin:0;
	color:black;
	min-width: auto;
	width: 55px;
	height:-webkit-max-content;
	height:max-content;

}

.table-print .table-row .cella:nth-child(-n+2) {
	width: 30px;
}

.table-print .table-row span{
	white-space:break-spaces;
	word-break:keep-all;
	line-height:10px;
}

.table-print{
    	width: 297mm;
}

.table-print * {
	font-size:8px;
	opacity:1;
	visibility:visible;
}


@media only print{


	.data-filter-container{
		display:none;
		visibility:hidden;
	}

	.navbar{
		visibility:hidden;
		display:none;
	}

	.page{
		padding:8px;
		margin:0;
	}

	body{
		padding:0;
	}

	html, body {
		height: auto;
    		width: 100%;
  	}

	.table-container{
		position:absolute;
		top:0px;
		left:0;
		border:none;
		margin:0;
    		width: 100%;
		visibility:visible;
		overflow:visible;

		display:table;
		width:100vw;

		margin:0;
		height:-webkit-max-content;
		height:max-content;
		visibility:visible;
		overflow:visible;

	}



	.table-container * {
		width:100%;
		opacity:1;
		font-size:1vw;
		visibility:visible;
	}

	.table-row {
		height:-webkit-max-content;
		height:max-content;
		border:none;
		margin:5px 0;
		display:table-row !important;
	}




	.table-row .cella{
		background:white;
		border:none;
		padding:5px 2px;
		margin:0;
		color:black;
		height:-webkit-max-content;
		height:max-content;
		display:table-cell !important;
	}





	.table-row span{
		white-space:break-spaces;
		word-break:keep-all;
		line-height: 1.5vw !important;
	}

	.table-row.print-only{
		outline: 1px solid black;
	}

	.table-row.print-only *{
		font-weight: bolder;
		font-size: 1.3vw;
		white-space: nowrap;
	}

}







.table-row{

	display:flex;
	width:-webkit-max-content;
	width:max-content;

	color:var(--black);
}

.print-only{
	display: none;
}


.table-header{


	color:var(--white);
	font-weight:bolder;

	background-color:var(--navbar)
}


.table-header > .cella{
	height:55px;
}

.table-header > .cella > span{
	overflow:hidden;
}

.pagato{
	color:var(--white);
	background:#00704f
}

.non-pagato{
	color:var(--white);
	background:#852500;
}








.cella{

	min-height:50px;
	max-height:100px;
	width:170px;
	min-width:170px;
	display:flex;
	justify-content:center;
	align-items:center;
	padding:20px 10px;
	border-bottom:1px solid var(--grey);

}

.cella > span {
	overflow-x:auto;
}

.cella-children{

	flex-direction:column;
	overflow-y:auto;
}

.cella-children p {

	text-align:left;
	min-width:60%;
}


.cella * {
	white-space:nowrap;
}



@media only print {


	.cella{
		height:auto;
		max-height:unset; 
		min-width:unset;
		max-width:15vw;
		overflow:visible;
		overflow-wrap:break-word;
		width:auto;
		vertical-align:center;
	}

	.cella > span {
		vertical-align:center;
		overflow:visible;
		overflow-wrap:break-word;
		display:flex;
		justify-content:center;
		
	}

	.not-print{
		visibility:hidden;
		position:absolute;
		opacity:0;
	}
}




.checkbox{

	display:flex;
	align-items:center;

}

.checkbox > label{

	margin-right:10px;
}




.data-filter-container{
	width:80vw;
	margin:auto;
	display:flex;
	grid-gap:43px;
	gap:43px;
	flex-direction:column;
	justify-content:space-between;
}



.date-row{
	display:flex;
	justify-content:flex-start;
	grid-gap:34px;
	gap:34px;
	align-items:center;
	flex-wrap:wrap;
}







.email-modal{

	min-height:20vh;
	min-width:345px;
	padding: 12px 22px;
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
	overflow-Y:auto;
	display:flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:center;
	background:var(--modal-card-background);
}


.email-btn-wrap{
	margin:13px 0 0 0;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:space-around;
}




.navbar{
        height:56px;
        width:98vw;
        padding:8px;
        border-radius:3px;
        display:flex;
        justify-content:space-between;
        align-items:center;
	background:var(--navbar);
	position:fixed;
	z-index:999;
	color:var(--ivory);
	box-shadow: 2px 2px 10px black; 	

}


.navbar * {
      	-webkit-user-select:none;
      	    -ms-user-select:none;
      	        user-select:none; 
}


.navbar-logo{
	color:var(--ivory);
        margin-right:auto;
        margin-left:4%;
        position:relative;
        z-index:10;
        text-decoration:none;
}

.navbar-icon{
        margin-left:auto;
        margin-right:5%;
}







.navbar-mobile{
        top:54px;
	width:356px;
        height:89vh;
	padding-top:57px;
        position:absolute;
	display:flex;
        flex-direction:column;
        align-items:center;
	justify-content:flex-start;
	background-color:var(--navbar);
        border-radius: 0px 0px 10px 0px ;
	border-top:none;
	box-shadow: -7px 18px 11px 0px black;
	list-style:none;
        transition: left .3s ease;
	z-index:70;
}



.navbar-deactive{
        left:-100%;
}

.navbar-active{
        left:0;
}




@media only screen and (max-width:370px){

.navbar-deactive{
        left:-150%;
}


.navbar-mobile{
	width:256px;
}



}







.menu-item{
        text-decoration:none;
	-webkit-user-select:none;
	    -ms-user-select:none;
	        user-select:none;
	cursor:pointer;
	width:100%;
	min-height:85px;
	color:var(--ivory);
	box-shadow:-2px 1px 20px -10px black inset;
	display:inline-block;
	position:relative;
	z-index:80;
}


.menu-item:active{
	box-shadow:-1px 0px 46px -12px black inset;
	color:#00b9f2;
}


.menu-text{
	padding-left:58px;
	display:flex;
	flex-direction:row;
	grid-gap:42px;
	gap:42px;
	align-items:flex-start;
	margin-top:32px;
	
}


.menu-text p{
	width:89px;
	text-align:center;
}



.dropdown-menu {
	position:relative;
	list-style:none;
	background:var(--light-grey);
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	transition: height .4s ease, opacity .3s ease ;
	-webkit-user-select:none;
	    -ms-user-select:none;
	        user-select:none;
}

.dropdown-menu  * {

	-webkit-user-select:none;

	    -ms-user-select:none;

	        user-select:none;
}



.dropdown-active{
	opacity:10;
}
.dropdown-active li {
	height:65px;
	opacity:1;
}

.dropdown-deactive{
	height:0;
	opacity:0;
	pointer-events:none;
}

.dropdown-deactive li{
	height:0;
	opacity:0;
}
.dropdown-menu li  {
	width:100%;
	border-bottom:1px solid black;
	display:flex;
	align-items:center;
	justify-content:center;
	transition:height .5s ease, opacity .5s ease;
	z-index:80;

}




.dropdown-menu li a {
	color:var(--ivory);
	text-decoration:none;
	width:100%;
	text-align:center;
	line-height:65px;

}


.dropdown-menu li a:target {
	background:transparent;
}

.dropdown-menu li a:active{
	color:#00b9f2;
}






.month-change{
	margin: auto;
	width:70vw;
	min-height:83px;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	grid-gap:53px;
	gap:53px;
}

.month-change * {
	-webkit-user-select:none;
	    -ms-user-select:none;
	        user-select:none;
}


.picker{

	display:flex;
	justify-content:space-around;
	align-items:center;
	min-width:230px;
	font-size:1.45rem;
	text-align:center;
	align-content:center;
}


@media only screen and ( max-width:733px ){

	.month-change{
		grid-gap:0;
		gap:0;
	}
}



.arrow-button{


	height:20px;
	width:40px;

	font-size:1.2rem;
	display:flex;
	justify-content:center;
	align-items:center;
	border-radius:50px;

	background-color:var(--navbar);
	color:var(--ivory);
}

.calendar-container {
  color: black;
}

.days-wrap {
  padding: 6px;
  margin: auto;
  background: var(--navbar);
  border-radius: 3px;
  width: auto;
  display: grid;

  grid-template-columns: repeat(8, 1fr);
}

.legenda {
  margin-top: 13px;
  padding-left: 13px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3px;
  align-items: center;
}

.legenda > div {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  grid-row-gap: 3px;
  align-items: center;
}

.go-up {
  position: fixed;
  bottom: 30px;
  right: 40px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: var(--light-blue);
  color: white;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.leg {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border: 1px solid var(--black);
}

.leg-birthday {
  background-color: var(--orange);
}

.leg-hb {
  background-color: var(--purple);
}

.leg-laser {
  background-color: var(--laser);
}

.leg-evento {
  background-color: var(--light-blue);
}

@media only screen and (max-width: 1900px) {
  .days-wrap {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media only screen and (max-width: 1700px) {
  .days-wrap {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .days-wrap {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .days-wrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .days-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .days-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .days-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media only screen and ( max-width:800px ){ */
/* 	.days-wrap{ */
/* 		grid-template-columns: repeat( 6, 1fr );  */
/* 	} */
/**/
/* } */
/* @media only screen and ( max-width:800px ){ */
/* 	.days-wrap{ */
/* 		grid-template-columns: repeat( 6, 1fr );  */
/* 	} */
/**/
/* } */
/* @media only screen and ( max-width:800px ){ */
/* 	.days-wrap{ */
/* 		grid-template-columns: repeat( 6, 1fr );  */
/* 	} */
/**/
/* } */

@media only screen and (max-width: 500px) {
  .legenda {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 550px) {
  .days-wrap {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 9px;
    gap: 9px;
  }
}

.calendar-day {
  border: 1px solid black;
  background: var(--white);
  margin: 2px;
  border-radius: 3px;
  min-height: 90px;
  width: minmax(250px, 550px);
}

.day-header {
  height: 70px;
  padding: 10px 1rem 0 0.5rem;
  border-bottom: 1px solid black;
  font-size: 1.3rem;
  color: var(--red);
  font-weight: bold;
}

.day-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day-header > div > span {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
}

.day-header > div > span > svg {
  color: black;

  margin-left: 3px;
}

.day-header > div > svg {
  font-size: 1.6rem;
  color: var(--add-icon);
  height: 1.6rem;
  width: 1.6rem;
}

.day-header > p {
  text-align: right;
}

.day-header .totale-bambini > span:nth-child(1) {
  color: var(--orange);
}

.day-header .totale-bambini > span:nth-child(2) {
  color: var(--purple);
}

.day-header .totale-bambini > span:nth-child(3) {
  color: var(--vr);
}

.day-header .totale-bambini > span:nth-child(4) {
  color: black;
}

.day-header .totale-bambini > span:nth-child(5) {
  color: black;
}

.totale-bambini {
  letter-spacing: 2px;
  font-size: 0.95rem;
}

.totale-bambini > svg {
  color: black;
}

.day-mobile-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.day-mobile-info > p {
  color: var(--red);
}

@media only screen and (max-width: 780px) {
  /* .calendar-day{ */
  /* 	min-height:80px; */
  /* 	width:130px; */
  /* } */

  /* .day-header{ */
  /* 	height:60px; */
  /* 	padding: .3rem 1rem 0 .5rem; */
  /* 	border-bottom: 1px solid black; */
  /* 	display:flex; */
  /* 	flex-direction:column; */
  /* 	justify-content:space-around; */
  /* 	align-items:flex-start; */
  /**/
  /* } */

  .day-header > p {
    white-space: nowrap;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 550px) {
  .calendar-day {
    border-radius: 3px;
    width: 100%;
  }

  /* .day-header p { */
  /* 	font-size:1.4rem; */
  /* } */

  /* .day-header{ */
  /* 	padding-left:3px; */
  /* 	height:auto; */
  /* 	justify-content:center; */
  /* 	align-items:center; */
  /* } */

  .day-header svg {
    font-size: 1.4rem;
    height: 1.4rem;
    width: 1.4rem;
  }

  .day-header label {
    font-size: 1.5rem;
  }

  .day-header > div > svg {
    height: 2.3rem;
    width: 2.3rem;
  }

  .day-mobile-info {
    padding: 0 13px 3px 13px;
    align-items: center;
  }
}

.blue {
  background-color: var(--light-blue);
}

.orange {
  background-color: var(--orange);
}

.purple.pranzo {
  background: linear-gradient(var(--purple), var(--pranzo));
}

.happy-pn.pranzo {
  background: linear-gradient(var(--happy-pn), var(--pranzo));
}

.vr.pranzo {
  background: linear-gradient(var(--vr), var(--pranzo));
}

.laser.pranzo {
  background: linear-gradient(var(--laser), var(--pranzo));
}

.purple {
  background-color: var(--purple);
}

.happy-pn {
  background-color: var(--happy-pn);
}

.vr {
  background-color: var(--vr);
}

.laser {
  background-color: var(--laser);
}
.pranzo {
  background-color: var(--pranzo);
}

.tipo-compleanno {
  font-size: 0.8rem;
}

.calendar-event-container {
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-event {
  width: 100%;
  border-top: 0.5px solid transparent;
  border-bottom: 0.5px solid transparent;
  padding: 0;
  padding: 3px 8px;
  margin: 6px 0;
  box-shadow: 1px 1px 1px 1px;
  line-height: 1.2rem;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  grid-gap: 8px;
  gap: 8px;
}

.calendar-event-info {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 1fr;
}

.calendar-event-time {
  font-weight: bolder;
  text-decoration: underline;
  font-size: 1.15rem;
}

.calendar-event p {
  text-overflow: ellipsis;
}
.calendar-event:active {
  box-shadow: 0.2px 0.2px 0.2px 0.2px;
}

@media only screen and (max-width: 790px) {
  /* .calendar-event-info{ */
  /* 	grid-template-columns: 1fr 2fr 1fr ;  */
  /* 	row-gap: 5px; */
  /* 	grid-template-rows: 1fr 1fr; */
  /* } */
}

@media only screen and (max-width: 550px) {
  .calendar-event {
    font-size: 1rem;
    white-space: normal;
  }

  .calendar-event-info {
    grid-template-columns: 1fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-template-rows: 1fr;
  }

  .calendar-event {
    grid-gap: 8px;
    gap: 8px;
  }
}




.new-modal{

	height:30vh;
	width:60vw;
	min-height: 200px;
	min-width: 300px;
	padding: 12px 12px;
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
	overflow-Y:auto;
	display:flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:center;
	background:var(--modal-card-background);
}



.new-modal > div {
	width:80%;
	display:flex;
	justify-content:space-around;
}






@media only screen and ( max-width:770px ){
	.new-modal > div {
		flex-direction:column;
		grid-gap:23px;
		gap:23px;
	}

}

