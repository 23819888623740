.calendar-day {
  border: 1px solid black;
  background: var(--white);
  margin: 2px;
  border-radius: 3px;
  min-height: 90px;
  width: minmax(250px, 550px);
}

.day-header {
  height: 70px;
  padding: 10px 1rem 0 0.5rem;
  border-bottom: 1px solid black;
  font-size: 1.3rem;
  color: var(--red);
  font-weight: bold;
}

.day-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day-header > div > span {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
}

.day-header > div > span > svg {
  color: black;

  margin-left: 3px;
}

.day-header > div > svg {
  font-size: 1.6rem;
  color: var(--add-icon);
  height: 1.6rem;
  width: 1.6rem;
}

.day-header > p {
  text-align: right;
}

.day-header .totale-bambini > span:nth-child(1) {
  color: var(--orange);
}

.day-header .totale-bambini > span:nth-child(2) {
  color: var(--purple);
}

.day-header .totale-bambini > span:nth-child(3) {
  color: var(--vr);
}

.day-header .totale-bambini > span:nth-child(4) {
  color: black;
}

.day-header .totale-bambini > span:nth-child(5) {
  color: black;
}

.totale-bambini {
  letter-spacing: 2px;
  font-size: 0.95rem;
}

.totale-bambini > svg {
  color: black;
}

.day-mobile-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.day-mobile-info > p {
  color: var(--red);
}

@media only screen and (max-width: 780px) {
  /* .calendar-day{ */
  /* 	min-height:80px; */
  /* 	width:130px; */
  /* } */

  /* .day-header{ */
  /* 	height:60px; */
  /* 	padding: .3rem 1rem 0 .5rem; */
  /* 	border-bottom: 1px solid black; */
  /* 	display:flex; */
  /* 	flex-direction:column; */
  /* 	justify-content:space-around; */
  /* 	align-items:flex-start; */
  /**/
  /* } */

  .day-header > p {
    white-space: nowrap;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 550px) {
  .calendar-day {
    border-radius: 3px;
    width: 100%;
  }

  /* .day-header p { */
  /* 	font-size:1.4rem; */
  /* } */

  /* .day-header{ */
  /* 	padding-left:3px; */
  /* 	height:auto; */
  /* 	justify-content:center; */
  /* 	align-items:center; */
  /* } */

  .day-header svg {
    font-size: 1.4rem;
    height: 1.4rem;
    width: 1.4rem;
  }

  .day-header label {
    font-size: 1.5rem;
  }

  .day-header > div > svg {
    height: 2.3rem;
    width: 2.3rem;
  }

  .day-mobile-info {
    padding: 0 13px 3px 13px;
    align-items: center;
  }
}
