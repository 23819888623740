


.card{

	width:30vw;
	min-width:255px;
	max-width:350px;
	min-height:246px;
	padding: .5rem; 
	padding-top:2rem;
	border-radius:4px;

	background:var(--navbar);

	display:flex;
	flex-direction:column;
	justify-content:space-around;

	box-shadow:3px 3px 20px black;
	
}
