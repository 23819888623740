



.table-container{

	overflow:auto;
	width:80vw;
	margin:5vh auto;
	max-height:90vh;
	border-radius:3px;
	border:1px solid var(--grey);
	z-index: 20;
	position: relative;
	display:block;
}




.table-print{
	margin:0;
	height:max-content;
	width:max-content;
	visibility:visible;
	overflow:visible;
}

.table-print .table-row {
	height:max-content;
	border:none;
	margin:5px 0;
}




.table-print .table-row .cella{
	background:white;
	border:none;
	padding:5px 2px;
	margin:0;
	color:black;
	min-width: auto;
	width: 55px;
	height:max-content;

}

.table-print .table-row .cella:nth-child(-n+2) {
	width: 30px;
}

.table-print .table-row span{
	white-space:break-spaces;
	word-break:keep-all;
	line-height:10px;
}

.table-print{
    	width: 297mm;
}

.table-print * {
	font-size:8px;
	opacity:1;
	visibility:visible;
}


@media only print{


	.data-filter-container{
		display:none;
		visibility:hidden;
	}

	.navbar{
		visibility:hidden;
		display:none;
	}

	.page{
		padding:8px;
		margin:0;
	}

	body{
		padding:0;
	}

	html, body {
		height: auto;
    		width: 100%;
  	}

	.table-container{
		position:absolute;
		top:0px;
		left:0;
		border:none;
		margin:0;
    		width: 100%;
		visibility:visible;
		overflow:visible;

		display:table;
		width:100vw;

		margin:0;
		height:max-content;
		visibility:visible;
		overflow:visible;

	}



	.table-container * {
		width:100%;
		opacity:1;
		font-size:1vw;
		visibility:visible;
	}

	.table-row {
		height:max-content;
		border:none;
		margin:5px 0;
		display:table-row !important;
	}




	.table-row .cella{
		background:white;
		border:none;
		padding:5px 2px;
		margin:0;
		color:black;
		height:max-content;
		display:table-cell !important;
	}





	.table-row span{
		white-space:break-spaces;
		word-break:keep-all;
		line-height: 1.5vw !important;
	}

	.table-row.print-only{
		outline: 1px solid black;
	}

	.table-row.print-only *{
		font-weight: bolder;
		font-size: 1.3vw;
		white-space: nowrap;
	}

}
