



.text-list-container{
	display:flex;
	flex-direction:column;
	gap:24px;
}


.text-list-container .text-input{
	height:25px;
}
