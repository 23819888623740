



.month-change{
	margin: auto;
	width:70vw;
	min-height:83px;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	gap:53px;
}

.month-change * {
	user-select:none;
}


.picker{

	display:flex;
	justify-content:space-around;
	align-items:center;
	min-width:230px;
	font-size:1.45rem;
	text-align:center;
	align-content:center;
}


@media only screen and ( max-width:733px ){

	.month-change{
		gap:0;
	}
}
