.calendar-container {
  color: black;
}

.days-wrap {
  padding: 6px;
  margin: auto;
  background: var(--navbar);
  border-radius: 3px;
  width: auto;
  display: grid;

  grid-template-columns: repeat(8, 1fr);
}

.legenda {
  margin-top: 13px;
  padding-left: 13px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3px;
  align-items: center;
}

.legenda > div {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  grid-row-gap: 3px;
  align-items: center;
}

.go-up {
  position: fixed;
  bottom: 30px;
  right: 40px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: var(--light-blue);
  color: white;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.leg {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border: 1px solid var(--black);
}

.leg-birthday {
  background-color: var(--orange);
}

.leg-hb {
  background-color: var(--purple);
}

.leg-laser {
  background-color: var(--laser);
}

.leg-evento {
  background-color: var(--light-blue);
}

@media only screen and (max-width: 1900px) {
  .days-wrap {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media only screen and (max-width: 1700px) {
  .days-wrap {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .days-wrap {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .days-wrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .days-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .days-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .days-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media only screen and ( max-width:800px ){ */
/* 	.days-wrap{ */
/* 		grid-template-columns: repeat( 6, 1fr );  */
/* 	} */
/**/
/* } */
/* @media only screen and ( max-width:800px ){ */
/* 	.days-wrap{ */
/* 		grid-template-columns: repeat( 6, 1fr );  */
/* 	} */
/**/
/* } */
/* @media only screen and ( max-width:800px ){ */
/* 	.days-wrap{ */
/* 		grid-template-columns: repeat( 6, 1fr );  */
/* 	} */
/**/
/* } */

@media only screen and (max-width: 500px) {
  .legenda {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 550px) {
  .days-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
  }
}
