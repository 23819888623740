@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

:root {
  --background: #f7f7f7;
  --black: #0c1713;
  --orange: #ff9b42;
  --salmon: #f7a072;
  --light-blue: #0fa3b1;
  --blue: #3d82c2;
  --champigne: #eddea4;
  --white: #fcfcff;
  --border: #d97720;
  --yellow: #f9f871;
  --dark-blue: #005361;
  --red: #e1402b;
  --grey: #9d9995;
  --menu-grey: #4f4f4f;
  --light-grey: #737375;
  --dark: #242424;
  --navbar: #393e41;
  --ivory: #f6f7eb;
  --text: #402e32;
  --orange-contr: #12078a;
  --add-icon: #008a64;
  --modal-card-background: rgba(12, 23, 19, 0.9);
  --btn-green: #05711b;
  --btn-yellow: #b76a06;
  --purple: #d63fdb;
  --pranzo: #ffd700;
  --happy-pn: #fb7eff;
  --vr: #03c460;
  --laser: #03c460;
}

/*
 *
	--navbar:#FF9B42;
 *
 * */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

html {
  height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  font-size: 16px;
  color: var(--black);
}

body {
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  background-color: var(--background);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media only screen and (max-width: 760px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: 330px) {
  html {
    font-size: 13px;
  }
}
