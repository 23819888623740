


.divider{
	display:flex;
	margin:0 auto;
	align-items:center;
	width:90vw;

}


.title{

	color:var(--dark-blue);
	white-space: nowrap;
	margin-right:16px;
}

.line{
	height:1px;
	border-bottom:1px solid var(--border);
	width:100%;
}
