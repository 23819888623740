



.navbar-mobile{
        top:54px;
	width:356px;
        height:89vh;
	padding-top:57px;
        position:absolute;
	display:flex;
        flex-direction:column;
        align-items:center;
	justify-content:flex-start;
	background-color:var(--navbar);
        border-radius: 0px 0px 10px 0px ;
	border-top:none;
	box-shadow: -7px 18px 11px 0px black;
	list-style:none;
        transition: left .3s ease;
	z-index:70;
}



.navbar-deactive{
        left:-100%;
}

.navbar-active{
        left:0;
}




@media only screen and (max-width:370px){

.navbar-deactive{
        left:-150%;
}


.navbar-mobile{
	width:256px;
}



}




