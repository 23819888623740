

.modal-close-icon{
	width:100%;
	margin-bottom:23px;
	font-size:1.6rem;
	color:white;
	display:flex;
	justify-content:flex-end;
}


.event-detail{
	width:480px;
	max-width:100vw;
	min-height:150px;
	max-height:80vh;
	padding: 12px 34px;
	background: var(--modal-card-background);
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
	overflow-Y:auto;
	display:flex;
	flex-direction:column;
}


.event-detail  > button{
	margin:0 auto 0 auto;
	margin-bottom:13px;
}


.detail-modal-list{



	max-height:60vh;
	list-style:none;
	margin-bottom:23px;
	overflow-Y:auto;
}


.detail-modal-list label{

	font-weight:bold;
	color:#e39c13;
}



.detail-modal-list span{
	
	width:100%;
	overflow-wrap:anywhere;
	color:white;
	user-select:text;
}





@media only screen and (max-width:430px ){

	.event-detail{
		max-height:97vh;
	}
}

