




.cella{

	min-height:50px;
	max-height:100px;
	width:170px;
	min-width:170px;
	display:flex;
	justify-content:center;
	align-items:center;
	padding:20px 10px;
	border-bottom:1px solid var(--grey);

}

.cella > span {
	overflow-x:auto;
}

.cella-children{

	flex-direction:column;
	overflow-y:auto;
}

.cella-children p {

	text-align:left;
	min-width:60%;
}


.cella * {
	white-space:nowrap;
}



@media only print {


	.cella{
		height:auto;
		max-height:unset; 
		min-width:unset;
		max-width:15vw;
		overflow:visible;
		overflow-wrap:break-word;
		width:auto;
		vertical-align:center;
	}

	.cella > span {
		vertical-align:center;
		overflow:visible;
		overflow-wrap:break-word;
		display:flex;
		justify-content:center;
		
	}

	.not-print{
		visibility:hidden;
		position:absolute;
		opacity:0;
	}
}
