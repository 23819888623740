






.loader-wrap{

	display:flex;
	gap:3rem;
	align-items:center;
}





.loader-wrap .loader-circle:nth-child(1){
	animation: ball1 ease-out 1.3s infinite; 
}

.loader-wrap .loader-circle:nth-child(2){
	animation: ball1 ease-out 1.3s infinite .25s; 
}


.loader-wrap .loader-circle:nth-child(3){
	animation: ball1 ease-out 1.3s infinite .5s; 
}

.loader-wrap .loader-circle:nth-child(4){
	animation: ball1 ease-out 1.3s infinite .75s; 
}



.loader-circle{

	width:5px;
	height:5px;
	border-radius:4rem;
	background:var(--light-blue);


}






@keyframes ball1 {


	0%{
		opacity:100%;
		transform:scale(100%);
	}
	100%{
		opacity:0%;
		transform:scale(750%);
	}
}
