
.dropdown{


	height:28px;
	background:white;
	padding:0 20px;
	border-radius:5%;
}



.dropdown-container{

	position:relative;
}
