




.textarea-container{
	display:flex;
	flex-direction:column;
	align-items:flex-start;
}

.textarea{
	resize:none;
	background:var(--white);
	border:none;
	border:2px ridge var(--light-blue);
	border-radius:4px;
	padding:.3rem;

}


.textarea:focus{

	border:2px ridge var(--orange);

}
