.blue {
  background-color: var(--light-blue);
}

.orange {
  background-color: var(--orange);
}

.purple.pranzo {
  background: linear-gradient(var(--purple), var(--pranzo));
}

.happy-pn.pranzo {
  background: linear-gradient(var(--happy-pn), var(--pranzo));
}

.vr.pranzo {
  background: linear-gradient(var(--vr), var(--pranzo));
}

.laser.pranzo {
  background: linear-gradient(var(--laser), var(--pranzo));
}

.purple {
  background-color: var(--purple);
}

.happy-pn {
  background-color: var(--happy-pn);
}

.vr {
  background-color: var(--vr);
}

.laser {
  background-color: var(--laser);
}
.pranzo {
  background-color: var(--pranzo);
}

.tipo-compleanno {
  font-size: 0.8rem;
}

.calendar-event-container {
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-event {
  width: 100%;
  border-top: 0.5px solid transparent;
  border-bottom: 0.5px solid transparent;
  padding: 0;
  padding: 3px 8px;
  margin: 6px 0;
  box-shadow: 1px 1px 1px 1px;
  line-height: 1.2rem;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 8px;
}

.calendar-event-info {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 1fr;
}

.calendar-event-time {
  font-weight: bolder;
  text-decoration: underline;
  font-size: 1.15rem;
}

.calendar-event p {
  text-overflow: ellipsis;
}
.calendar-event:active {
  box-shadow: 0.2px 0.2px 0.2px 0.2px;
}

@media only screen and (max-width: 790px) {
  /* .calendar-event-info{ */
  /* 	grid-template-columns: 1fr 2fr 1fr ;  */
  /* 	row-gap: 5px; */
  /* 	grid-template-rows: 1fr 1fr; */
  /* } */
}

@media only screen and (max-width: 550px) {
  .calendar-event {
    font-size: 1rem;
    white-space: normal;
  }

  .calendar-event-info {
    grid-template-columns: 1fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr;
    row-gap: 0px;
    grid-template-rows: 1fr;
  }

  .calendar-event {
    gap: 8px;
  }
}
