



.text-input-container{

	max-width:20vw;
	min-width:200px;
        padding:0;
        position:relative;
}



.text-input{

	width:100%;
	background:var(--white);
	border:none;
	border:2px ridge var(--light-blue);
	border-radius:4px;
	padding:.3rem;
	user-select:auto;

}


.text-input:focus{

	border:2px ridge var(--orange);

}



.input-label{
	font-size:.9rem;
	left:10px;
	transition: 	.1s font-size ease-in,
       			.1s top ease-in,
		       	.1s left ease-in,
			.1s color ease-in;

	position:absolute;
	user-select:none;
	opacity:.7;
}

.error-label{
	color:red;
	font-size:.7rem;
	line-height:15px;
	position:absolute;
}


