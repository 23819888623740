



.btn{
	min-height:30px;
	min-width:150px;
	width: auto;
	max-width: 250px;
	position:relative;
	overflow:hidden;
	background:var(--light-blue);
	border-radius:4px;
	border:none;
	color:var(--white);
	padding:.3rem;
	box-shadow:1px 1px 5px black;
	letter-spacing:.05rem;
	font-size:.8rem;
}



.btn:active{
	box-shadow:1px 1px 1px black;
}


.primary{
        background-color:var(--light-blue);
        border:1px solid var(--light-blue);
        box-shadow:.4px .4px 1px rgba(0, 126, 167, .5);
}



.secondary{
        background-color:var(--red);
        border:1px solid var(--red);
        box-shadow:.4px .4px 1px rgba(225, 64, 43, .5);
}


.green{
        background-color:var(--btn-green);
        border:1px solid var(--btn-green);
        box-shadow:.4px .4px 1px rgba(5, 85, 2, 0.5);
}


.yellow{

        background-color:var(--btn-yellow);
        border:1px solid var(--btn-yellow);
        box-shadow:.4px .4px 1px rgba(5, 85, 2, 0.5);

}


.ripple {
        z-index:100;
        position: absolute;
	display:inline-block;
        transform: translate(-50%, -50%);
	background:rgb(240,240,240);
        pointer-events: none;
        border-radius: 50%;
        animation: animate .5s linear forwards;
}

	
	
@keyframes animate {
        0% {
                width: 0px;
                height: 0px;
                opacity: 0.5;
        }
        100% {
                width: 500px;
                height: 500px;
                opacity: .1;
        }
}



@media only screen and ( max-width:330px ){


	.btn{
		min-height:unset;
	}

}


