


.new-modal{

	height:30vh;
	width:60vw;
	min-height: 200px;
	min-width: 300px;
	padding: 12px 12px;
	border:1px solid var(--black);
	border-radius:3px;
	color:white;
	overflow-Y:auto;
	display:flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:center;
	background:var(--modal-card-background);
}



.new-modal > div {
	width:80%;
	display:flex;
	justify-content:space-around;
}






@media only screen and ( max-width:770px ){
	.new-modal > div {
		flex-direction:column;
		gap:23px;
	}

}
