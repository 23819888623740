
.modal-btn-action-wrap{

	width:100%;
	display:flex;
	flex-direction:column;
	gap:18px;
	justify-content: center;
	align-items: center;
}


@media only screen and (max-width:430px ){

	.modal-btn-action-wrap{
		gap:13px;
	}
}

