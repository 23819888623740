
.card-page{

	text-align:center;
	width:100vw;
	padding-bottom:16vh;
	height:80vh;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-content:center;
	align-items:center;
}

.card-page h1, h2, h3{
	color: var(--ivory);
}


.prenotazioni-page{
	display:flex;
	flex-direction:column;
	align-items:center;
}


.page{
	padding-top:89px;
}


.calendar-page{
	padding:58px;
}

.modal-container{
	position:fixed;
	width:100vw;
	top:0;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	background: rgba(12,23,19,.5);
	z-index:999;
}


@media only screen and (max-width:840px){


}

@media only screen and (max-width:1500px){
	.calendar-page{
		padding:12px;
	}
}

@media only screen and (max-width:330px){
	.calendar-page{
		padding:0;
	}
}
