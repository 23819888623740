





.table-row{

	display:flex;
	width:max-content;

	color:var(--black);
}

.print-only{
	display: none;
}


.table-header{


	color:var(--white);
	font-weight:bolder;

	background-color:var(--navbar)
}


.table-header > .cella{
	height:55px;
}

.table-header > .cella > span{
	overflow:hidden;
}

.pagato{
	color:var(--white);
	background:#00704f
}

.non-pagato{
	color:var(--white);
	background:#852500;
}


