


.checkbox{

	display:flex;
	align-items:center;

}

.checkbox > label{

	margin-right:10px;
}
