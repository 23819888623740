



.form{

	height:100%;
	width:95%;
	margin:12px auto;
	padding:44px;
	display:flex;
	gap:60px 40px;
	align-items:flex-end;
	justify-content:flex-start;
	flex-wrap:wrap;
	top:0;
	background:var(--navbar);
	border-radius:10px;
	transition: height .2s ease-in, opacity .2s ease-in  ;
	pointer-events: all;
}


.form div input,textarea{
	user-select:auto;
}


.label-fix{
	color:var(--light-blue);
	font-weight:bolder;
	font-size:.7rem;
	margin-left:2px;
	position:absolute;
	transform:translateY(-25px);
	white-space: nowrap;
}


@media only screen and ( max-width:330px ){
	.form{
		padding-left:13px;
		padding-right:13px;
	}
}
