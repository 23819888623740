


.data-filter-container{
	width:80vw;
	margin:auto;
	display:flex;
	gap:43px;
	flex-direction:column;
	justify-content:space-between;
}



.date-row{
	display:flex;
	justify-content:flex-start;
	gap:34px;
	align-items:center;
	flex-wrap:wrap;
}


