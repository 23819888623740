



.prenotazioni-btn-wrap{
	margin-top:30px;
	margin-bottom:30px;
	min-width:300px;
	display:flex;
	justify-content: center;
	gap: 86px;
}

@media only screen and ( max-width:330px ){
	.prenotazioni-btn-wrap{
		gap: 40px;
		flex-direction:column;
		width:80vw;
		min-width:unset;
	}
}
